.status {
    margin: 0 10px;
}

.title {
    font-size: 24px;
    text-align: center;
    font-weight: bolder;
}

.subtitle {
    font-size: 12px;
}

.danger { color: #FF1616 }
.warning { color: #FAAD14 }
.success { color: #52C41A }
