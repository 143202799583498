.progressBox {
    margin-bottom: 40px;
    width: 100%;
    border: 1px solid #e9e9e9;
}
.progressRow {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0 0;
}
.hideMessage {
    float: right;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color .2s;
}
.hideMessage:hover {
    color: rgba(0, 0, 0, 0.65);
}
.progressSteps {
    margin: 0 50px 20px;
}
.content {
    margin-bottom: 20px;
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    padding: 15px 15px 5px;
}
.title {
    font-weight: bold;
}
.status {
    padding: 20px 50px;
}
.link {
    color: #1890ff;
    cursor: pointer;
}
.link:hover {
    color: #40a9ff;
}
.planningButton {
    width: 100%;
    margin: auto;
    text-align: center;
}
.info { color: #B2B2B2 }
.danger { color: #FF1616 }
.warning { color: #FAAD14 }
.success { color: #52C41A }
.defaultText { color: #666 }

.centerText {
    text-align: center;
    padding-top: 5px;
}