html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
}
body.iFrameEmbedded {
    background-color: transparent;
}

#app {
    font-family: avenir, sans-serif;
}
#app h1,
#app h2,
#app h3,
#app h4,
#app h5,
#app h6,
#app .ant-page-header-heading-title {
    color: rgba(0, 0, 0, 0.65);
    font-family: avenir, sans-serif;
}
#app h2,
#app .ant-page-header-heading-title {
    font-weight: 500;
    font-size: 20px;
}
#app .ant-page-header {
    padding: 0;
    margin: 0 5px;
}
#app .ant-layout ~ .ant-page-header {
    margin-top: 20px;
}
#app .ant-page-header .ant-page-header-heading-left,
#app .ant-page-header .ant-page-header-heading-extra {
    margin: 0;
    align-items: initial;
}
