.info { color: #B2B2B2 }
.danger { color: #FF1616 }
.warning { color: #FAAD14 }
.success { color: #52C41A }

.inactive {
    color: #B2B2B2;
    text-decoration: line-through;
}

.status {
    text-align: center;
}
.error{
    font-size: 12px;
    color: #FF1616;
    border: 1px solid red;
    padding: 5px;
    white-space: nowrap;
    background: #FEF1F0;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    width: 150px;
}
.stage {
    box-shadow: 0 0 5px 0 #ccc;
    margin: 5px;
}
.errorContainer{
    margin-left: 30px;
}
