.status {
    display: flex;
    align-items: center;
}

.text {
    margin: 5px 0 5px 5px;
}

.info { color: #B2B2B2 }
.danger { color: #FF1616 }
.warning { color: #FAAD14 }
.success { color: #52C41A }