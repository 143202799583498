.pointer {
    cursor: pointer;
}

.description {
    font-size: 15px;
}

.linkText {
    margin-top: 20px;
}

.linkFile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.linkBox {
    padding: 10px;
    background: #fafafa;
    border-radius: 3px;
    border: 1px solid #dadada;
    margin: 5px 0;
    text-align: center;
}

.note {
    font-style: italic;
    font-size: 12px;
    color: #999
}