.danger { color: #FF1616 }
.warning { color: #FAAD14 }
.success { color: #52C41A }

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.title {
    font-size: 24px;
}

.statusBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.uploader {
    padding: 20px;
    background-color: #FdFdFd;
    box-shadow: 0 0 5px 0 #ccc;
    margin: 5px;
}

.fileTypes {
    font-size: 12px;
    font-weight: lighter;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 10px;
}

.message {
    margin: 20px auto 5px;
}

.fileTypesBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.primaryFileType {
    font-weight: bold;
    margin: 5px auto;
}
.secondaryFileTypes {

}
.secondaryFilesBlock {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 500px) {
    .header {
        flex-direction: column;
    }
}

@media (max-width: 900px) {
    .fileTypesBlock {
        align-items: center;
    }
    .secondaryFilesBlock {
        flex-direction: column;
        align-items: flex-start;
    }
}
